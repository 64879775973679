<template>
  <div class="ui-wizard-step" v-if="active">
    <div class="ui-wizard-step__title">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiWizardStep',
  props: {
    title: {
      type: String,
      required: true,
    },
    beforeChange: {
      type: Function,
    },
  },
  inject: ['addTab'],
  data() {
    return {
      active: false,
      tabNumber: '',
    }
  },
  mounted() {
    this.addTab(this)
  },
}
</script>

<style lang="scss" scoped>
.ui-wizard-step {
  width: 100%;
  max-width: 650px;

  &__title {
    margin-bottom: $gutter-mobile;
    font-size: $font-size-md;
    font-weight: 600;
  }
}
</style>
