<template>
  <div class="screen-breadcrumb">
    <router-link tag="a" :to="{ name: 'Home' }" class="screen-breadcrumb__home backoffice-icons"> home </router-link>
    <div class="screen-breadcrumb__item" v-for="(breadcrumb, idx) in breadcrumbs" :key="`breadcrumb-${idx}`">
      <div class="screen-breadcrumb__item__dot"></div>
      <component
        :is="idx !== breadcrumbs.length - 1 ? 'router-link' : 'div'"
        :to="idx !== breadcrumbs.length - 1 ? breadcrumb.route : null"
        class="screen-breadcrumb__item__label"
        :class="{ 'screen-breadcrumb__item__label--with-link': idx !== breadcrumbs.length - 1 }"
      >
        {{ breadcrumb.label }}
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenBreadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-breadcrumb {
  display: flex;
  align-items: center;

  &__home {
    margin-right: -2px;
    margin-left: -2px;
    text-decoration: none;
    color: var(--text-color-alt);
    font-size: $font-size-xl;

    &:hover {
      color: $generic-color-gtr;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:last-child {
      overflow: hidden;
    }

    &__dot {
      margin: 0 8px;
      border-radius: 50%;
      background-color: var(--text-color-alt);
      width: 4px;
      min-width: 4px;
      height: 4px;
      min-height: 4px;
      line-height: 4px;
    }

    &__label {
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--text-color-alt);
      font-size: $font-size-sm;
      font-weight: 500;

      &--with-link {
        &:hover {
          color: $generic-color-gtr;
        }
      }
    }
  }
}
</style>
