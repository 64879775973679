var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-tag"},[(_vm.showLabel)?_c('div',{staticClass:"ui-tag__label ui-tag__label--full",class:{
      'ui-tag__label--error': _vm.error,
    }},[_vm._v(" "+_vm._s(_vm.tagLabel)+" ")]):_vm._e(),_c('multiselect',{staticClass:"ui-tag__multiselect",class:{
      'ui-tag__multiselect--no-absolute': _vm.noAbsolute,
      'ui-tag__multiselect--error': _vm.error,
      'ui-tag__multiselect--no-options': _vm.options.length === 0,
    },attrs:{"value":_vm.frontOfficesTags,"options":_vm.options,"placeholder":_vm.placeholder,"label":_vm.label ? _vm.label : null,"track-by":_vm.trackBy ? _vm.trackBy : null,"select-label":"","selected-label":"","deselect-label":"","clear-on-select":_vm.clearOnSelect,"searchable":_vm.searchable,"multiple":true,"taggable":_vm.taggable,"tag-position":"bottom","tag-placeholder":"","close-on-select":false,"max-height":_vm.maxHeight},on:{"tag":_vm.onTag,"input":_vm.onInput,"select":_vm.onSelectTag,"search-change":_vm.onSearchChange},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
    var option = ref.option;
    var remove = ref.remove;
return _c('span',{staticClass:"ui-tag__multiselect__tag",class:("ui-tag__multiselect__tag--" + _vm.variant)},[_vm._t("tag",function(){return [_c('span',{staticClass:"ui-tag__multiselect__tag__label"},[_vm._v(_vm._s(_vm.label ? option[_vm.label] : option))])]},{"tag":option}),_c('span',{staticClass:"ui-tag__multiselect__tag__icon backoffice-icons",on:{"click":function($event){return remove(option)}}},[_vm._v("close")])],2)}},{key:"option",fn:function(ref){
    var option = ref.option;
return [_vm._t("option",null,{"option":option})]}}],null,true)},[_c('template',{slot:"caret"},[_c('span')]),_c('template',{slot:"noOptions"},[_c('span')]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t('ui.dropdown.noResult')))])],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.helper || _vm.error)?_c('div',{staticClass:"ui-tag__helper",class:{ 'ui-tag__helper--error': _vm.error }},[_vm._t("helper",function(){return [_vm._v(_vm._s(_vm.helper))]})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }