<template>
  <div
    class="skeleton-line"
    :style="{ marginRight, marginLeft, height, marginTop, width, maxWidth }"
    :class="{ 'skeleton-line--no-radius': noRadius }"
  ></div>
</template>

<script>
export default {
  name: 'SkeletonLine',
  props: {
    marginRight: {
      type: String,
      required: false,
      default: '',
    },
    marginLeft: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    height: {
      type: String,
      required: true,
    },
    marginTop: {
      type: String,
      required: false,
      default: '',
    },
    noRadius: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '300px',
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton-line {
  @include skeleton-loader;

  border-radius: $radius-button;

  &--no-radius {
    border-radius: 0;
  }
}
</style>
