<template>
  <div class="front-offices-create">
    <ui-wizard
      :title="$t('frontOffices.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
    >
      <ui-wizard-step :title="$t('frontOffices.create.step.client')" :before-change="checkClient">
        <ui-dropdown
          class="front-offices-create__input"
          id="locator-client"
          v-model="client"
          :options="brands"
          :placeholder="$t('frontOffices.create.client.placeholder')"
          :dropdown-label="$t('frontOffices.create.client.label')"
          :is-required="$v.client.required"
          label="name"
          track-by="id"
          :show-label="true"
          :error="$v.client.$error"
        >
          <template v-if="$v.client.$error && !$v.client.required">
            {{ $t('errors.required') }}
          </template>
        </ui-dropdown>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('frontOffices.create.step.locator')" :before-change="checkLocator">
        <ui-input
          class="front-offices-create__input"
          v-model.trim="name"
          :label="$t('frontOffices.create.name')"
          :is-required="true"
          id="locator-name"
          :error="$v.name.$error"
        >
          <template v-if="$v.name.$error && !$v.name.required">
            {{ $t('errors.required') }}
          </template>
        </ui-input>
        <ui-input
          class="front-offices-create__input"
          v-model.trim="url"
          :is-required="true"
          :label="$t('frontOffices.create.url')"
          id="locator-url"
          :error="$v.url.$error"
        >
          <template v-if="$v.url.$error && !$v.url.required">
            {{ $t('errors.required') }}
          </template>
        </ui-input>
        <ui-input
          class="front-offices-create__input"
          v-model.trim="tag"
          :is-required="true"
          :label="$t('frontOffices.create.tag')"
          id="locator-tag"
          :error="$v.tag.$error"
        >
          <template v-if="$v.tag.$error && !$v.tag.required">
            {{ $t('errors.required') }}
          </template>
          <template v-else-if="$v.tag.$error && !$v.tag.syntax">
            {{ $t('errors.tag') }}
          </template>
        </ui-input>
        <ui-dropdown
          class="front-offices-create__input"
          v-model="defaultLocale"
          :options="defaultLocaleOptions"
          label="name"
          track-by="id"
          :placeholder="$t('frontOffices.create.locale.placeholder')"
          :dropdown-label="$t('frontOffices.create.locale.defaultLocale')"
          :is-required="true"
          :show-label="true"
          :error="$v.defaultLocale.$error"
          @input="refreshOtherLocales"
        >
          <template v-slot:helper v-if="$v.defaultLocale.$error && !$v.defaultLocale.required">
            {{ $t('errors.required') }}
          </template>
        </ui-dropdown>
        <ui-tag
          v-if="otherLocalesOptions.length > 0"
          v-model="otherLocales"
          :options="otherLocalesOptions"
          label="name"
          track-by="id"
          :placeholder="$t('frontOffices.create.locale.placeholder')"
          :tag-label="$t('frontOffices.create.locale.otherLocales')"
          :show-label="true"
          @input="setLocales"
        />
      </ui-wizard-step>
      <ui-wizard-step :title="$t('frontOffices.create.step.summary')">
        <div class="front-offices-create__grid">
          <screen-block
            :title="$t('frontOffices.create.label.client')"
            icon="business"
            :has-data="typeof client !== null && typeof client.name === 'string'"
            :data="client.name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('frontOffices.create.label.locator')"
            icon="location"
            :has-data="name !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('frontOffices.create.label.url')"
            icon="link"
            :has-data="url !== ''"
            :data="url"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('frontOffices.create.label.tag')"
            icon="attribut"
            :has-data="tag !== ''"
            :data="tag"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('frontOffices.create.label.defaultLanguage')"
            icon="language"
            :has-data="defaultLocale !== null"
            :data="defaultLocale ? defaultLocale.name : ''"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('frontOffices.create.label.otherLanguage')"
            icon="language"
            :has-data="otherLocales.length > 0"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <span v-for="(locale, idx) in otherLocales" :key="`locales-${idx}`">
                {{ locale.name }}<template v-if="idx < otherLocales.length - 1">, </template>
              </span>
            </template>
          </screen-block>
        </div>
      </ui-wizard-step>
    </ui-wizard>
  </div>
</template>

<script>
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { tag } from '@/validators/tag.validator'

export default {
  name: 'FrontOfficesCreate',
  components: {
    UiWizard,
    UiWizardStep,
    UiDropdown,
    UiInput,
    UiTag,
    ScreenBlock,
  },
  mixins: [notif],
  data() {
    return {
      client: null,
      name: '',
      url: '',
      tag: '',
      defaultLocale: null,
      otherLocales: [],
      isSaving: false,
      wizardIndex: 0,
      locator: null,
    }
  },
  created() {
    this.client = this.brands[0]
  },
  computed: {
    ...mapState({
      brands: state => state.user.brands,
      locales: state => state.user.locales,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('frontOffices.list.breadcrumb'),
          route: {
            name: 'FrontOffices',
          },
        },
        {
          label: this.$t('frontOffices.create.breadcrumb'),
          route: {
            name: 'FrontOfficeCreate',
          },
        },
      ]
    },
    defaultLocaleOptions() {
      const locales = this.locales.filter(locale => this.client.locales.includes(locale.id))
      locales.push(this.locales.find(locale => locale.id === this.client.defaultLocaleId))
      return locales
    },
    otherLocalesOptions() {
      if (this.defaultLocale) {
        return this.defaultLocaleOptions.filter(locale => locale.id !== this.defaultLocale.id)
      }
      return []
    },
  },
  methods: {
    ...mapActions({
      createFrontOffice: 'frontoffice/createFrontOffice',
      updateCurrentClient: 'user/updateCurrentClient',
    }),
    onBack() {
      this.$router.push({ name: 'FrontOffices' })
    },
    setLocales(locales) {
      this.otherLocales = locales
    },
    refreshOtherLocales() {
      this.otherLocales = this.otherLocales.filter(locale => locale.id !== this.defaultLocale.id)
    },
    onComplete() {
      this.isSaving = true
      this.createFrontOffice({
        name: this.name,
        url: this.url,
        algoliaIndex: this.tag,
        clientId: this.client.id,
        defaultLocaleId: this.defaultLocale.id,
        locales: this.otherLocales.map(locale => locale.id),
      })
        .then(locator => {
          this.locator = locator
          return this.updateCurrentClient(this.client)
        })
        .then(() => {
          this.notificationSuccess('frontOffice', this.locator.name, 'create')
          this.$router.push({ name: 'FrontOffice', params: { id: this.locator.id } })
          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
          this.notificationError()
        })
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkClient() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    checkLocator() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
  },
  validations() {
    return {
      client: {
        required,
      },
      name: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      url: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      tag: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
        syntax: this.wizardIndex === 1 && tag ? tag : false,
      },
      defaultLocale: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.front-offices-create {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet / 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop / 2);
    }
  }
}
</style>
