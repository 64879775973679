<template>
  <div class="ui-tag">
    <div
      class="ui-tag__label ui-tag__label--full"
      :class="{
        'ui-tag__label--error': error,
      }"
      v-if="showLabel"
    >
      {{ tagLabel }}
    </div>
    <multiselect
      class="ui-tag__multiselect"
      :class="{
        'ui-tag__multiselect--no-absolute': noAbsolute,
        'ui-tag__multiselect--error': error,
        'ui-tag__multiselect--no-options': options.length === 0,
      }"
      :value="frontOfficesTags"
      :options="options"
      :placeholder="placeholder"
      :label="label ? label : null"
      :track-by="trackBy ? trackBy : null"
      select-label=""
      selected-label=""
      deselect-label=""
      :clear-on-select="clearOnSelect"
      :searchable="searchable"
      :multiple="true"
      :taggable="taggable"
      tag-position="bottom"
      tag-placeholder=""
      :close-on-select="false"
      :max-height="maxHeight"
      @tag="onTag"
      @input="onInput"
      @select="onSelectTag"
      @search-change="onSearchChange"
    >
      <template slot="caret">
        <span></span>
      </template>
      <template slot="noOptions">
        <span></span>
      </template>
      <span
        slot="tag"
        slot-scope="{ option, remove }"
        class="ui-tag__multiselect__tag"
        :class="`ui-tag__multiselect__tag--${variant}`"
      >
        <slot name="tag" :tag="option">
          <span class="ui-tag__multiselect__tag__label">{{ label ? option[label] : option }}</span>
        </slot>
        <span class="ui-tag__multiselect__tag__icon backoffice-icons" @click="remove(option)">close</span>
      </span>
      <template slot-scope="{ option }" slot="option">
        <slot name="option" :option="option"> </slot>
      </template>
      <span slot="noResult">{{ $t('ui.dropdown.noResult') }}</span>
    </multiselect>

    <transition name="fade">
      <div class="ui-tag__helper" :class="{ 'ui-tag__helper--error': error }" v-if="helper || error">
        <slot name="helper">{{ helper }}</slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { debounce } from '@/utils/func.util'

export default {
  name: 'UiTag',
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    trackBy: {
      type: String,
      required: false,
      default: '',
    },
    noAbsolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'data',
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    tagLabel: {
      type: String,
      required: false,
      default: '',
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 200,
    },
    customTag: {
      type: Boolean,
      required: false,
      default: false,
    },
    taggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    frontOfficesTags() {
      if (this.customTag) {
        let frontOfficesTagsList = []
        this.value.forEach(currentLocationTag => {
          this.options.filter(frontOfficesTags =>
            frontOfficesTags.tag === currentLocationTag ? frontOfficesTagsList.push(frontOfficesTags) : null
          )
        })
        return frontOfficesTagsList
      }
      return this.value
    },
  },
  methods: {
    onTag(value) {
      /**
       * Emitted when a new tag is added.
       * @event tag
       * @type {Event}
       */
      this.$emit('tag', value)
    },
    onSelectTag(value) {
      /**
       * Emitted when a new tag selected on options dropdown.
       * @event select
       * @type {Event}
       */
      this.$emit('select', value)
    },
    onInput(value) {
      /**
       * Emitted when a tag is removed.
       * @event input
       * @type {Event}
       */
      this.$emit('input', value)
    },
    onSearchChange:
      /**
       * Emitted when a tag is removed.
       * @event search
       * @type {Event}
       */
      debounce(function (value) {
        this.$emit('search', value)
      }, 300),
  },
}
</script>

<style lang="scss">
.ui-tag {
  &__label {
    @include input-label;
  }

  &__multiselect {
    z-index: 3;

    &--no-absolute {
      .multiselect {
        &__content-wrapper {
          position: relative;
        }
      }
    }

    &--error {
      .multiselect__tags,
      .multiselect__label {
        border-color: map-get($generic-color-variants, 'error');
      }
    }

    &--no-options {
      &.multiselect--active .multiselect__tags {
        border-radius: $radius-input;
      }
      .multiselect {
        &__content-wrapper {
          display: none;
        }
      }
    }

    &__tag {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
      margin-bottom: 8px;
      border-radius: $tag-radius;
      padding: $tag-padding;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      white-space: nowrap;
      color: $generic-color-text;

      @each $name, $variant in $generic-color-variants {
        &--#{$name} {
          background-color: $variant;
        }
      }

      &__label {
        margin-right: 6px;
        color: $generic-color-text-alt;
        font-size: $font-size-sm;
        font-weight: $tag-label-font-weight;
      }

      &__icon {
        color: $generic-color-text-alt;
        font-size: $tag-icon-font-size;

        &:hover {
          cursor: pointer;
          color: $generic-color-text-alt;
        }
      }
    }
  }

  &__helper {
    @include input-helper;
  }
}
</style>
